import { CurrencyTypeMissing } from '@/src/interfaces/missing-types';

const currencies = {
  [CurrencyTypeMissing.TRY]: {
    country: 'tr-TR',
    code: 'TRY',
    name: 'TL',
    symbol: '₺',
  },
  [CurrencyTypeMissing.USD]: {
    country: 'en-US',
    code: 'USD',
    name: 'USD',
    symbol: '$',
  },
  [CurrencyTypeMissing.EUR]: {
    country: 'de-DE',
    code: 'EUR',
    name: 'EUR',
    symbol: '€',
  },
};

const fmt = (
  number: number | undefined,
  currency: CurrencyTypeMissing,
  showCurrency = true,
  asSymbol = false,
  minimumFractionDigits = 2
) => {
  if (number == undefined) return;
  const curr = currencies[currency];
  const formatter = new Intl.NumberFormat(curr.country, {
    style: 'currency',
    currency: curr.code,
    minimumFractionDigits,
    currencyDisplay: 'code',
  });

  let formattedNumber;
  if (curr) {
    formattedNumber = formatter.format(number).replaceAll(curr.code, '').trim();
  } else {
    formattedNumber = number.toString();
  }

  return `${asSymbol && curr ? curr.symbol : ''}${formattedNumber}${showCurrency && curr && !asSymbol ? ' ' + curr.name : ''}`;
};

export default fmt;
